// import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      // beforeResolve(routeTo, routeFrom, next) {
      //   // If the user is already logged in
      //   if (store.getters["auth/loggedIn"]) {
      //     // Redirect to the home page instead
      //     next({ name: "default" });
      //   } else {
      //     // Continue to the login page
      //     next();
      //   }
      // },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      // beforeResolve(routeTo, routeFrom, next) {
      //   // If the user is already logged in
      //   if (store.getters["auth/loggedIn"]) {
      //     // Redirect to the home page instead
      //     next({ name: "default" });
      //   } else {
      //     // Continue to the login page
      //     next();
      //   }
      // },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      // beforeResolve(routeTo, routeFrom, next) {
      //   // If the user is already logged in
      //   if (store.getters["auth/loggedIn"]) {
      //     // Redirect to the home page instead
      //     next({ name: "default" });
      //   } else {
      //     // Continue to the login page
      //     next();
      //   }
      // },
    },
  },

  // DASHBOARD
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/index.vue"),
  },

  // COMPANY
  {
    path: "/companies/edit",
    name: "companies-edit",
    meta: {
      title: "Ubah Company",
      authRequired: true,
    },
    component: () => import("../views/companies/edit"),
  },

  //SettingPPN
  {
    path: "/companies/editppn",
    name: "companies-editppn",
    meta: {
      title: "Ubah Default Ppn",
      authRequired: true,
    },
    component: () => import("../views/companies/editppn"),
  },

  // MASTER ITEM
  {
    path: "/masters/items/create",
    name: "masters-items-create",
    meta: {
      title: "Master Item",
      authRequired: true,
    },
    component: () => import("../views/masters/items/create"),
  },
  {
    path: "/masters/items",
    name: "masters-items",
    meta: {
      title: "Daftar Item",
      authRequired: true,
    },
    component: () => import("../views/masters/items/list"),
  },
  {
    path: "/masters/itemsAgen",
    name: "masters-itemsAgen",
    meta: {
      title: "Daftar Item",
      authRequired: true,
    },
    component: () => import("../views/masters/items/DaftarItemAgen"),
  },
  {
    path: "/masters/importItemsAgen",
    name: "masters-importItemsAgen",
    meta: {
      title: "Daftar Item",
      authRequired: true,
    },
    component: () => import("../views/masters/items/importSaldoStockAwal"),
  },
  {
    path: "/masters/importHargaAgen",
    name: "masters-importHargaAgen",
    meta: {
      title: "Daftar Item",
      authRequired: true,
    },
    component: () => import("../views/masters/items/importSaldoHargaAwal"),
  },
  {
    path: "/masters/settingHarga",
    name: "masters-settingHarga",
    meta: {
      title: "Setting Harga",
      authRequired: true,
    },
    component: () => import("../views/masters/items/SettingHarga"),
  },
  {
    path: "/masters/items/import",
    name: "masters-items-import",
    meta: {
      title: "Import Item",
      authRequired: true,
    },
    component: () => import("../views/masters/items/import"),
  },

  // MASTER Status Apps
  {
    path: "/masters/statusApps",
    name: "masters-statusapps",
    meta: {
      title: "Daftar Status Apps",
      authRequired: true,
    },
    component: () => import("../views/masters/status-apps/list"),
  },

  // MASTER SALES
  {
    path: "/masters/sales",
    name: "masters-sales",
    meta: {
      title: "Daftar Sales",
      authRequired: true,
    },
    component: () => import("../views/masters/sales/list"),
  },

  // MASTER SALES APP
  {
    path: "/masters/sales-app",
    name: "masters-sales-app",
    meta: {
      title: "Daftar Sales App",
      authRequired: true,
    },
    component: () => import("../views/masters/sales-app/list"),
  },

  // MASTER CUSTOMER
  {
    path: "/masters/customers/create",
    name: "masters-customers-create",
    meta: {
      title: "Customer",
      authRequired: true,
    },
    component: () => import("../views/masters/customers/create"),
  },
  {
    path: "/masters/customers",
    name: "masters-customers",
    meta: {
      title: "Daftar Customer",
      authRequired: true,
    },
    component: () => import("../views/masters/customers/list"),
  },

  {
    path: "/masters/customers/import",
    name: "masters-customers-import",
    meta: {
      title: "Import Customer",
      authRequired: true,
    },
    component: () => import("../views/masters/customers/import"),
  },

  // MASTER SUPLIER
  {
    path: "/masters/suppliers",
    name: "masters-suppliers",
    meta: {
      title: "Daftar Supplier",
      authRequired: true,
    },
    component: () => import("../views/masters/suppliers/list"),
  },

  // MASTER Bank
  {
    path: "/masters/keuangan/bank",
    name: "masters-bank",
    meta: {
      title: "Daftar Bank",
      authRequired: true,
    },
    component: () => import("../views/masters/keuangan/bank/list"),
  },

  // MASTER KAS
  {
    path: "/masters/keuangan/kas",
    name: "masters-kas",
    meta: {
      title: "Daftar Kas",
      authRequired: true,
    },
    component: () => import("../views/masters/keuangan/kas/list"),
  },

  // Pemasukan Kas
  {
    path: "/masters/keuangan/jenispemasukan",
    name: "masters-jenispemasukan",
    meta: {
      title: "Daftar Jenis Pemasukan Kas",
      authRequired: true,
    },
    component: () => import("../views/masters/keuangan/pemasukan/list"),
  },

  // Pengeluaran Kas
  {
    path: "/masters/keuangan/jenispengeluaran",
    name: "masters-jenispengeluaran",
    meta: {
      title: "Daftar Jenis Pengeluaran Kas",
      authRequired: true,
    },
    component: () => import("../views/masters/keuangan/pengeluaran/list"),
  },

  // PEMBELIAN
  {
    path: "/transactions/pembelian/create/select-items",
    name: "transactions-pembelian-create",

    meta: {
      title: "Pembelian - Daftar Item",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/pembelian/created/select-items"),
  },

  {
    path: "/transactions/pembelian/create/items-cart",
    name: "transactions-pembelian-create-cart",
    meta: {
      title: "Pembelian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/pembelian/created/items-cart"),
  },

  {
    path: "/transactions/pembelian/create/items-cart-pembelian",
    name: "transactions-pembelian-create-cart-pembelian",
    meta: {
      title: "Pembelian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/pembelian/created/createPembelian"),
  },

  {
    path: "/transactions/pembelian/create/items-cart-review-pembelian",
    name: "transactions-pembelian-create-cart-review-pembelian",
    meta: {
      title: "Pembelian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/pembelian/created/previewPembelian"),
  },

  {
    path: "/transactions/pembelian/create/items-cart-approval-pembelian",
    name: "transactions-pembelian-create-cart-approval-pembelian",
    meta: {
      title: "Pembelian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/pembelian/created/previewApprovalPembelian"),
  },

  {
    path: "/transactions/pembelian/create/invoice",
    name: "transactions-pembelian-create-invoice",
    meta: {
      title: "Pembelian",
      authRequired: true,
    },
    component: () => import("../views/transactions/pembelian/created/invoice"),
  },

  {
    path: "/transactions/pembelian",
    name: "transactions-pembelian-list",
    meta: {
      title: "Daftar Pembelian",
      authRequired: true,
    },
    component: () => import("../views/transactions/pembelian/list"),
  },

  // SALES ORDER
  {
    path: "/transactions/sales-order/create/select-items",
    name: "transactions-sales-order-create",
    meta: {
      title: "Sales Order - Daftar Item",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/sales-order/create/select-items"),
  },

  {
    path: "/transactions/sales-order/create/items-cart",
    name: "transactions-sales-order-create-cart",
    meta: {
      title: "Sales Order",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/sales-order/create/createSalesOrder"),
  },

  {
    path: "/transactions/sales-order/create/invoice",
    name: "transactions-sales-order-create-invoice",
    meta: {
      title: "Sales Order - Invoice",
      authRequired: true,
    },
    component: () => import("../views/transactions/sales-order/create/invoice"),
  },

  {
    path: "/transactions/sales-order/create/previewinvoice",
    name: "transactions-sales-order-preview-invoice",
    meta: {
      title: "Sales Order - Invoice",
      authRequired: true,
    },
    component: () => import("../views/transactions/sales-order/create/previewSalesOrder"),
  },

  {
    path: "/transactions/sales-order",
    name: "transactions-sales-order-list",
    meta: {
      title: "Daftar Sales Order",
      authRequired: true,
    },
    component: () => import("../views/transactions/sales-order/list"),
  },

  // PENJUALAN
  {
    path: "/transactions/penjualan/create/cart",
    name: "transactions-invoice-create",
    meta: {
      title: "Penjualan",
      authRequired: true,
    },
    component: () => import("../views/transactions/penjualan/createInvoice"),
  },


  {
    path: "/transactions/penjualan/create/previewCart",
    name: "transactions-invoice-preview-create",
    meta: {
      title: "Penjualan",
      authRequired: true,
    },
    component: () => import("../views/transactions/penjualan/previewPenjualan"),
  },

  {
    path: "/transactions/penjualan/create/select-items",
    name: "transactions-invoice-select-item",
    meta: {
      title: "Penjualan - Daftar Item",
      authRequired: true,
    },
    component: () => import("../views/transactions/penjualan/select-items"),
  },

  {
    path: "/transactions/penjualan",
    name: "transactions-invoice-list",
    meta: {
      title: "Daftar Invoice",
      authRequired: true,
    },
    component: () => import("../views/transactions/penjualan/list"),
  },

  {
    path: "/transactions/penjualan/print",
    name: "transactions-invoice-print",
    meta: {
      title: "Print Invoice",
      authRequired: true,
    },
    component: () => import("../views/transactions/penjualan/print"),
  },

  {
    path: "/transactions/penjualan/printLaba",
    name: "transactions-invoice-print-laba",
    meta: {
      title: "Print Invoice Laba",
      authRequired: true,
    },
    component: () => import("../views/transactions/penjualan/print-laba"),
  },

  //Penyesuaian

  {
    path: "/transactions/penyesuaian/create/cart",
    name: "transactions-penyesuaian-create",
    meta: {
      title: "Penyesuaian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/penyesuaian/created/items-cart"),
  },

  {
    path: "/transactions/penyesuaian/create/select-items",
    name: "transactions-penyesuaian-select-item",
    meta: {
      title: "Penyesuaian - Daftar Item",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/penyesuaian/created/select-items"),
  },

  {
    path: "/transactions/penyesuaian",
    name: "transactions-penyesuaian-list",
    meta: {
      title: "Daftar Penyesuaian",
      authRequired: true,
    },
    component: () => import("../views/transactions/penyesuaian/list"),
  },

  {
    path: "/transactions/penyesuaian/print",
    name: "transactions-penyesuaian-create-invoice",
    meta: {
      title: "Penyesuaian Clain",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/penyesuaian/created/invoice"),
  },

  //Migrasi

  {
    path: "/transactions/migrasi/create/cart",
    name: "transactions-migrasi-create",
    meta: {
      title: "Migration Stock",
      authRequired: true,
    },
    component: () => import("../views/transactions/migrasi/created/items-cart"),
  },

  {
    path: "/transactions/migrasi/create/select-items",
    name: "transactions-migrasi-select-item",
    meta: {
      title: "Migration Stock - Daftar Item",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/migrasi/created/select-items"),
  },

  {
    path: "/transactions/migrasi",
    name: "transactions-migrasi-list",
    meta: {
      title: "Daftar Migration Stock",
      authRequired: true,
    },
    component: () => import("../views/transactions/migrasi/list"),
  },

  {
    path: "/transactions/migrasi/print",
    name: "transactions-migrasi-create-invoice",
    meta: {
      title: "Migration Stock",
      authRequired: true,
    },
    component: () => import("../views/transactions/migrasi/created/invoice"),
  },

  //CLAIM
  {
    path: "/transactions/claim/create/cart",
    name: "transactions-claim-create",
    meta: {
      title: "Claim",
      authRequired: true,
    },
    component: () => import("../views/transactions/claim/created/items-cart"),
  },

  {
    path: "/transactions/claim/create/select-items",
    name: "transactions-claim-select-item",
    meta: {
      title: "Claim - Daftar Item",
      authRequired: true,
    },
    component: () => import("../views/transactions/claim/created/select-items"),
  },

  {
    path: "/transactions/claim",
    name: "transactions-claim-list",
    meta: {
      title: "Daftar Claim",
      authRequired: true,
    },
    component: () => import("../views/transactions/claim/list"),
  },

  {
    path: "/transactions/claim/print",
    name: "transactions-claim-create-invoice",
    meta: {
      title: "Print Clain",
      authRequired: true,
    },
    component: () => import("../views/transactions/claim/created/invoice"),
  },

  //Pinjaman
  {
    path: "/transactions/pinjaman/create/cart",
    name: "transactions-pinjaman-create",
    meta: {
      title: "Pinjaman",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/pinjaman/created/items-cart"),
  },

  {
    path: "/transactions/pinjaman/create/select-items",
    name: "transactions-pinjaman-select-item",
    meta: {
      title: "Pinjaman - Daftar Item",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/pinjaman/created/select-items"),
  },

  {
    path: "/transactions/pinjaman",
    name: "transactions-pinjaman-list",
    meta: {
      title: "Daftar Pinjaman",
      authRequired: true,
    },
    component: () => import("../views/transactions/pinjaman/list"),
  },

  {
    path: "/transactions/pinjaman/print",
    name: "transactions-pinjaman-create-invoice",
    meta: {
      title: "Print Pinjaman",
      authRequired: true,
    },
    component: () => import("../views/transactions/pinjaman/created/invoice"),
  },

  //Keuangan
  {
    path: "/transactions/keuangan/bkmUmum",
    name: "transactions-bkmumum-list",
    meta: {
      title: "Daftar BKM Umum",
      authRequired: true,
    },
    component: () => import("../views/transactions/keuangan/bkm-umum/list"),
  },

  {
    path: "/transactions/keuangan/bkmUmum/item-cart",
    name: "transactions-bkmumum",
    meta: {
      title: "BKM Umum",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkm-umum/create/items-cart"),
  },

  {
    path: "/transactions/keuangan/bkmUmum/select-item",
    name: "transactions-bkmumum-jenis-pemasukan",
    meta: {
      title: "Jenis BKM Umum",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkm-umum/create/select-items.vue"),
  },

  {
    path: "/transactions/keuangan/bkmUmum/print",
    name: "transactions-bkmumum-create-invoice",
    meta: {
      title: "Print BKM Umum",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkm-umum/create/invoice"),
  },

  {
    path: "/transactions/keuangan/bkkUmum",
    name: "transactions-bkkumum-list",
    meta: {
      title: "Daftar BKK Umum",
      authRequired: true,
    },
    component: () => import("../views/transactions/keuangan/bkk-umum/list"),
  },

  {
    path: "/transactions/keuangan/bkkUmum/item-cart",
    name: "transactions-bkkumum",
    meta: {
      title: "BKK Umum",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkk-umum/create/items-cart"),
  },

  {
    path: "/transactions/keuangan/bkkUmum/select-item",
    name: "transactions-bkkumum-jenis-pemasukan",
    meta: {
      title: "Jenis BKK Umum",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkk-umum/create/select-items.vue"),
  },

  {
    path: "/transactions/keuangan/bkkUmum/print",
    name: "transactions-bkkUmum-create-invoice",
    meta: {
      title: "Print BKK Umum",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkk-umum/create/invoice"),
  },

  {
    path: "/transactions/keuangan/bkmPenjualan",
    name: "transactions-bkmpenjualan-list",
    meta: {
      title: "Daftar BKM Penjualan",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkm-penjualan/list"),
  },

  {
    path: "/transactions/keuangan/bkmPenjualan/item-cart",
    name: "transactions-bkmpenjualan",
    meta: {
      title: "BKM Penjualan",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkm-penjualan/create/items-cart"),
  },

  {
    path: "/transactions/keuangan/bkmPenjualan/select-item",
    name: "transactions-bkmpenjualan-jenis-pemasukan",
    meta: {
      title: "Jenis BKM Penjualan",
      authRequired: true,
    },
    component: () =>
      import(
        "../views/transactions/keuangan/bkm-penjualan/create/select-items.vue"
      ),
  },

  {
    path: "/transactions/keuangan/bkmPenjualan/print",
    name: "transactions-bkmpenjualan-create-invoice",
    meta: {
      title: "Print BKM Penjualan",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkm-penjualan/create/invoice"),
  },

  {
    path: "/transactions/keuangan/bkkPembelian",
    name: "transactions-bkkpembelian-list",
    meta: {
      title: "Daftar BKK Pembelian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkk-pembelian/list"),
  },

  {
    path: "/transactions/keuangan/bkkPembelian/item-cart",
    name: "transactions-bkkpembelian",
    meta: {
      title: "BKK Pembelian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkk-pembelian/create/items-cart"),
  },

  {
    path: "/transactions/keuangan/bkkPembelian/select-item",
    name: "transactions-bkkpembelian-jenis-pemasukan",
    meta: {
      title: "Jenis BKK Pembelian",
      authRequired: true,
    },
    component: () =>
      import(
        "../views/transactions/keuangan/bkk-pembelian/create/select-items.vue"
      ),
  },

  {
    path: "/transactions/keuangan/bkkPembelian/print",
    name: "transactions-bkkpembelian-create-invoice",
    meta: {
      title: "Print BKK Pembelian",
      authRequired: true,
    },
    component: () =>
      import("../views/transactions/keuangan/bkk-pembelian/create/invoice"),
  },

  //Laporan
  {
    path: "/Report/Persediaan",
    name: "report-posisi-persediaan",
    meta: {
      title: "Lap. Posisi Persediaan",
      authRequired: true,
    },
    component: () => import("../views/Report/Persediaan/posisi-persediaan"),
  },

  {
    path: "/Report/PersediaanMigrationStock",
    name: "report-posisi-persediaan-migration-stock",
    meta: {
      title: "Lap. Posisi Persediaan Migration Stock",
      authRequired: true,
    },
    component: () =>
      import("../views/Report/Persediaan/posisi-persediaan-migration-stock"),
  },

  {
    path: "/Report/Persediaan/KartuStock",
    name: "report-kartu-stock",
    meta: {
      title: "Lap. Kartu Stock",
      authRequired: true,
    },
    component: () => import("../views/Report/Persediaan/kartu-stock"),
  },
  {
    path: "/Report/Penjualan/LapPenjualan",
    name: "report-lap-penjualan",
    meta: {
      title: "Lap. Penjualan",
      authRequired: true,
    },
    component: () => import("../views/Report/Penjualan/lap-penjualan"),
  },
  {
    path: "/Report/Penjualan/LapPenjualanItem",
    name: "report-lap-penjualanItem",
    meta: {
      title: "Lap. Penjualan Per Item",
      authRequired: true,
    },
    component: () => import("../views/Report/Penjualan/lap-penjualan-item"),
  },
  {
    path: "/Report/Penjualan/LapFixOrder",
    name: "report-lap-fixOrder",
    meta: {
      title: "Lap. Fix Order",
      authRequired: true,
    },
    component: () => import("../views/Report/Penjualan/lap-fixorder"),
  },
  {
    path: "/Report/Penjualan/LapPenjualanLaba",
    name: "report-lap-penjualan-laba",
    meta: {
      title: "Lap. Buku Penjualan",
      authRequired: true,
    },
    component: () => import("../views/Report/Penjualan/lap-penjualan-laba"),
  },
  {
    path: "/Report/Penjualan/LapOutStanding",
    name: "report-lap-outStanding",
    meta: {
      title: "Lap. Out Standing",
      authRequired: true,
    },
    component: () => import("../views/Report/Penjualan/lap-out-standing"),
  },
  {
    path: "/Report/Penjualan/LapPembelian",
    name: "report-lap-pembelian",
    meta: {
      title: "Lap. Pembelian",
      authRequired: true,
    },
    component: () => import("../views/Report/Pembelian/lap-pembelian"),
  },

  {
    path: "/Report/Pembelian/LapOutStandingMigrationStock",
    name: "report-lap-LapOutStandingMigrationStock",
    meta: {
      title: "Lap. Out Standing Migration Stock",
      authRequired: true,
    },
    component: () =>
      import("../views/Report/Pembelian/lap-out-standing-kontainer"),
  },

  {
    path: "/Report/Penyesuaian/LapMigrationStock",
    name: "report-lap-migrasi",
    meta: {
      title: "Lap. Migration Stock",
      authRequired: true,
    },
    component: () => import("../views/Report/Penyesuaian/lap-migrasi"),
  },

  {
    path: "/Report/Penyesuaian/LapPenyesuaian",
    name: "report-lap-penyesuaian",
    meta: {
      title: "Lap. Penyesuaian",
      authRequired: true,
    },
    component: () => import("../views/Report/Penyesuaian/lap-penyesuaian"),
  },

  {
    path: "/Report/Penyesuaian/LapClaim",
    name: "report-lap-claim",
    meta: {
      title: "Lap. Claim",
      authRequired: true,
    },
    component: () => import("../views/Report/Penyesuaian/lap-claim"),
  },

  {
    path: "/Report/Penyesuaian/LapPinjaman",
    name: "report-lap-pinjaman",
    meta: {
      title: "Lap. Pinjaman",
      authRequired: true,
    },
    component: () => import("../views/Report/Penyesuaian/lap-pinjaman"),
  },

  {
    path: "/Report/keuangan/LapPosisiKasBank",
    name: "report-lap-kasBank",
    meta: {
      title: "Lap. Posisi Kas Bank",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/posisi-kas-bank"),
  },

  {
    path: "/Report/keuangan/LapPosisiPiutangCustomer",
    name: "report-lap-piutangcustomer",
    meta: {
      title: "Lap. Posisi Piutang Customer",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/posisi-piutang-customer"),
  },

  {
    path: "/Report/keuangan/LapKartuPiutangCustomer",
    name: "report-lap-kartupiutangcustomer",
    meta: {
      title: "Lap. Kartu Piutang Customer",
      authRequired: true,
    },
    component: () =>
      import("../views/Report/keuangan/kartu-posisi_piutang_customer"),
  },

  {
    path: "/Report/keuangan/LapPosisiHutangSupplier",
    name: "report-lap-hutangsupplier",
    meta: {
      title: "Lap. Posisi Hutang Suplier",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/posisi-hutang-supplier"),
  },

  {
    path: "/Report/keuangan/LapKartuHutangSupplier",
    name: "report-lap-kartuhutangsupplier",
    meta: {
      title: "Lap. Kartu Hutang Supplier",
      authRequired: true,
    },
    component: () =>
      import("../views/Report/keuangan/kartu-posisi_hutang_supplier"),
  },

  {
    path: "/Report/keuangan/LapKartuKasBank",
    name: "report-lap-kartukasbank",
    meta: {
      title: "Lap. Kartu Kas Bank",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/kartu-posisi_kas_bank"),
  },

  {
    path: "/Report/keuangan/LapBKMUMUM",
    name: "report-lap-bkmumum",
    meta: {
      title: "Lap. BKM UMUM",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/lap-bkmumum"),
  },

  {
    path: "/Report/keuangan/LapBKKUMUM",
    name: "report-lap-bkkumum",
    meta: {
      title: "Lap. BKK UMUM",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/lap-bkkumum"),
  },

  {
    path: "/Report/keuangan/LapBKMPENJUALAN",
    name: "report-lap-bkmpenjualan",
    meta: {
      title: "Lap. BKM PENJUALAN",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/lap-bkmpenjualan"),
  },

  {
    path: "/Report/keuangan/LapBKKPEMBELIAN",
    name: "report-lap-bkkpembelian",
    meta: {
      title: "Lap. BKK PEMBELIAN",
      authRequired: true,
    },
    component: () => import("../views/Report/keuangan/lap-bkkpembelian"),
  },

  {
    path: "/Report/absensi/LapAbsensi",
    name: "report-lap-absensi",
    meta: {
      title: "Lap. Absensi",
      authRequired: true,
    },
    component: () => import("../views/Report/Absensi/lap-absensi"),
  },

  {
    path: "/Report/absensi/LapAbsensiDetail",
    name: "report-lap-absensi-detail",
    meta: {
      title: "Lap. Absensi Detail",
      authRequired: true,
    },
    component: () => import("../views/Report/Absensi/lap-absensi-detail"),
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      // authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/logout"),
  },

  // PENGATURAN USER
  {
    path: "/settings/users/create",
    name: "settings-users-create",
    meta: {
      title: "User",
      authRequired: true,
    },
    component: () => import("../views/settings/users/create"),
  },
  {
    path: "/settings/users",
    name: "settings-users",
    meta: {
      title: "Daftar User",
      authRequired: true,
    },
    component: () => import("../views/settings/users/list"),
  },


  {
    path: "/settings/role/create",
    name: "settings-role-create",
    meta: {
      title: "Role",
      authRequired: true,
    },
    component: () => import("../views/settings/role/create"),
  },
  {
    path: "/settings/role",
    name: "settings-role",
    meta: {
      title: "Daftar Role",
      authRequired: true,
    },
    component: () => import("../views/settings/role/list"),
  },
];
